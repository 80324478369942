import React from 'react';

// import components
import LatestProducts from '../components/LatestProducts.js';
import Hero from '../components/Hero.js';
import AdSenseScript from '../components/AdSenseScript'; 

const Home = () => {
  return (
    <section>
      <Hero />
      {/* Insertion de la publicité AdSense */}
      <AdSenseScript /> 
      <LatestProducts />
    </section>
  );
};

export default Home;
