import React, { useEffect } from 'react';

const AdSenseScript = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4122667868622106";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);  // Clean up the script when the component unmounts
    };
  }, []);

  return null;  
};

export default AdSenseScript;

