import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(true);  // Forcer à true pour tester l'affichage
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    console.log("Consent value:", consent);  // Ajouter un log pour voir la valeur
    if (!consent) {
      setShowBanner(true);
    } else if (consent === 'true') {
      addGoogleAnalytics();  // Activer Google Analytics si l'utilisateur a déjà accepté
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setConsentGiven(true);
    setShowBanner(false);
    addGoogleAnalytics();  // Activer Google Analytics après acceptation
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'false');
    setShowBanner(false);
  };

  const addGoogleAnalytics = () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-HXYYB8F9VH`; 
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-HXYYB8F9VH');
    };
  };

  return (
    showBanner && (
      <div style={styles.banner}>
        <p>Nous utilisons des cookies pour améliorer votre expérience. Acceptez-vous ces cookies supplémentaires ?</p>
        <button onClick={handleAccept} style={styles.button}>Accepter</button>
        <button onClick={handleDecline} style={styles.button}>Refuser</button>
      </div>
    )
  );
};

const styles = {
  banner: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#f7f7f7',  // Couleur douce et neutre pour le fond
    color: '#333',  // Texte en gris foncé pour une meilleure lisibilité
    padding: '20px',
    textAlign: 'center',
    boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',  // Ombre douce pour un effet de profondeur
    fontSize: '16px',  // Taille de police un peu plus grande
    zIndex: 9999,  // Assurez-vous que le bandeau est bien au-dessus des autres éléments
  },
  button: {
    marginLeft: '15px',
    padding: '10px 20px',
    backgroundColor: '#28a745',  // Couleur verte pour le bouton d'acceptation
    color: '#fff',  // Texte en blanc
    border: 'none',
    borderRadius: '5px',  // Bords arrondis pour un aspect plus doux
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'background-color 0.3s ease',  // Effet de transition sur le hover
  },
  declineButton: {
    marginLeft: '15px',
    padding: '10px 20px',
    backgroundColor: '#dc3545',  // Couleur rouge pour le bouton de refus
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'background-color 0.3s ease',  // Effet de transition sur le hover
  },
  buttonHover: {
    backgroundColor: '#218838',  // Couleur légèrement plus foncée au survol (acceptation)
  },
  declineButtonHover: {
    backgroundColor: '#c82333',  // Couleur légèrement plus foncée au survol (refus)
  }
};


export default CookieBanner;
