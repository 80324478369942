import React from 'react';
import { FaYoutube, FaInstagram, FaTwitter, FaFacebook, FaLinkedin } from 'react-icons/fa';
import NewsletterForm from './NewsletterForm';
const Footer = () => {
  return (
    <footer className='pt-16 bg-primary'>
      <div className='container mx-auto'>
        <div className='text-center'>
          <h2 className='h2 uppercase mb-6 font-semibold'>
          Abonnez-vous à notre newsletter
          </h2>
          <p className='text-white/70'>
          Soyez le premier à recevoir les dernières nouvelles sur les tendances, les promotions et
            beaucoup plus!
          </p>
        </div>
        {/* form */}
        <NewsletterForm />
        {/* links */}
        <div className='text-base text-white/60 flex gap-x-6 capitalize max-w-max mx-auto mb-9'>
        <a href='/mentions-legales' className='hover:text-white transition-all'>
          Mentions légales
          </a>
          <a href='/CGV' className='hover:text-white transition-all'>
          CGV
          </a>
          <a href='/PrivacyPolicy' className='hover:text-white transition-all'>
          politique de confidentialité
          </a>
          <a href='https://www.google.com/maps/place/Les+Lys+de+Boulogne/@48.8414795,2.239253,17z/data=!3m1!4b1!4m6!3m5!1s0x47e67b498d0ee2b5:0xe93dda07f1f47f04!8m2!3d48.8414795!4d2.239253!16s%2Fg%2F11pdgdqspx?entry=ttu&g_ep=EgoyMDI0MTAwMS4wIKXMDSoASAFQAw%3D%3D' className='hover:text-white transition-all'>
          Adresse
          </a>
        </div>
        {/* socials */}
        <div className='flex gap-x-6 max-w-max mx-auto text-lg mb-16'>
          <a href='https://WWW.youtube.com/@codeconcept' className='hover:text-white transition-all'>
            <FaYoutube />
          </a>
          <a href='https://www.instagram.com/makenzikenz/' className='hover:text-white transition-all'>
            <FaInstagram />
          </a>
          <a href='https://www.linkedin.com/in/kenza17-k?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app' className='hover:text-white transition-all'>
            <FaLinkedin />
          </a>
          <a href='https://www.facebook.com/azenka.hammoudi' className='hover:text-white transition-all'>
            <FaFacebook />
          </a>
        </div>
      </div>
      {/* copyright */}
      <div className='py-10 border-t border-t-white/10'>
        <div className='container mx-auto'>
          <div className='text-center text-sm text-white/60'>
            Copyright &copy; KENZA KACETE 2024. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
